import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

/**
 * turbo-history controller: manages history for turbo stream links
 * so standard browser functionality such as back/forward navigation
 * or bookmarking works as expected
 *
 * Only turbo requests that have the X-Turbo-History header in the response
 * will be processed by this controller. This allows for distinction between
 * a) turbo drive requests and turbo stream requests
 * b) different turbo stream requests where only a subset should result in history manipulation
 */
export default class extends Controller {
  connect() {
    this.fetchResponseHandler = this.onFetchResponse.bind(this);
    document.addEventListener(
      'turbo:before-fetch-response',
      this.fetchResponseHandler,
    );

    this.backForwardHandler = this.onBackForward.bind(this);
    window.addEventListener('popstate', this.backForwardHandler);
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-fetch-request',
      this.fetchRequestHandler,
    );
    window.removeEventListener('popstate', this.backForwardHandler);
  }

  // update the browser history and handle style updates to previously selected issue
  onFetchResponse(event) {
    const url = event.detail.fetchResponse.location.toString();
    const history = event.detail.fetchResponse.header('X-Turbo-History');

    // update URL and browser history. Borrowed from
    // https://github.com/hotwired/turbo/issues/792#issuecomment-1310399203
    // Other turbo stream history solutions such as
    // https://discuss.hotwired.dev/t/turbo-stream-with-update-url/4174
    // do mostly work, but have issues when e.g. navigating backwards in history to
    // the root link (which results in showing the wrong issue)
    //
    // Note that updating the URL also triggers the pageview to be reported to plausible
    if (url && history) {
      // push the turbo frame that initiated the request into the history state
      // so that later we can decide in the back/forward handler whether to handle
      // the action as a turbostream request or a full page reload. The latter is
      // needed when e.g. navigating back from a different page to the page that
      // initiated the turbostream request (and has the turbo frame ID)
      const { turboFrame } = event.srcElement.dataset;
      const state = {
        turbo_stream_history: true,
        turbo_frame: turboFrame,
      };
      window.history.replaceState(state, '', window.location.href);
      window.history.pushState(state, '', url);
    }
  }

  // Handles back and forward operations for turbo stream requests in the browser history
  onBackForward(event) {
    const url = window.location.href;
    if (event.state?.turbo_stream_history) {
      if (document.getElementById(event.state.turbo_frame)) {
        // The current page contains the turbo frame in which case we can
        // just send a turbo stream request and let turbo handle the response
        // (note this is a smoother UI experience then the turbo drive visit below)
        //
        // see https://www.writesoftwarewell.com/process-turbo-stream-javascript/
        fetch(url, {
          headers: {
            Accept: 'text/vnd.turbo-stream.html',
          },
        })
          .then((r) => r.text())
          .then((html) => Turbo.renderStreamMessage(html));
      } else {
        // The navigation is from a different page that does not contain
        // the turbo frame. In this case send a regular turbo drive request
        // to replace the entire page
        Turbo.visit(url, { action: 'replace' });
      }
    }
  }
}
