import { Controller } from '@hotwired/stimulus';

/**
 * toggle controller: Toggle visibility of elements on the page.
 *
 * If the event target has a "toggle-show-value" data attribute, its value is
 * matched to the same attribute value in the toggle targets to determine whether
 * to show them.  This allows for n toggle targets, rather than just 2.
 *
 * If an element that is shown after toggling has an autofocus child, that child
 * gets focus.
 */
export default class extends Controller {
  static targets = ['toggle'];

  static values = {
    toggledClass: String,
  };

  toggle(event) {
    const showType = event.target.dataset.toggleShowValue;
    const showTypes = showType?.split(',');

    const toggledClass = this.toggledClassValue;
    this.toggleTargets.forEach((toggle) => {
      const show = showTypes
        ? showTypes.includes(toggle.dataset.toggleShowValue)
        : undefined;

      toggle.classList.toggle('hide', showType != null ? !show : undefined);
      toggle.classList.toggle('show', show);
      const shown =
        toggle.classList.contains('show') || !toggle.classList.contains('hide');

      if (toggledClass) {
        toggledClass.split(' ').forEach((cssClass) => {
          toggle.classList.toggle(cssClass);
        });
      }

      if (shown) {
        toggle.querySelector('[autofocus]')?.focus();
      }
    });

    if (event.params.preventDefault) {
      event.preventDefault();
    }
  }
}
