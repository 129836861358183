import { Controller } from '@hotwired/stimulus';

/**
 * scroll-top controller: scrolls back to the top of page. The scroll icon is hidden
 * until the user scrolls about 200px down the page.
 */
export default class extends Controller {
  connect() {
    // hide by default
    this.hide();

    // track user scrolling
    this.scrollHandler = this.trackScrolling.bind(this);

    document.addEventListener('scroll', this.scrollHandler);

    // make sure icon shows if initial scroll position is not on top
    this.trackScrolling();
  }

  disconnect() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  hide() {
    this.element.style.display = 'none';
  }

  show() {
    this.element.style.display = 'block';
  }

  trackScrolling() {
    if (
      document.body.scrollTop > 200 ||
      document.documentElement.scrollTop > 200
    ) {
      this.show();
    } else {
      this.hide();
    }
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
