import { Controller } from '@hotwired/stimulus';

/**
 * cookies controller: Set cookie values via Stimulus action params
 *
 *   stimulus('cookies',
 *     action: set,
 *     action_params: { webForumTocState: collapsed })
 */
export default class extends Controller {
  set(event) {
    const expires = new Date();
    expires.setTime(expires.getTime() + 100 * 365 * 24 * 60 * 60 * 1000);
    Object.entries(event.params).forEach(([name, value]) => {
      document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/; Secure`;
    });
  }
}
