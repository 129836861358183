import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';

const profileIdAttr = 'data-posts--form-profile-id-value';
const uppyControllerName = 'uppy';

/**
 * areas--posts--form controller: control display of fields and related info.
 */
export default class extends Controller {
  static targets = [
    'belowLimit',
    'bodyField',
    'categoryContainer',
    'categoryHint',
    'categoryIdField',
    'destination',
    'destinationBelowLimitContainer',
    'destinationContainer',
    'destinationForum',
    'destinationForumButton',
    'directory',
    'eventContainer',
    'eventsMatchController',
    'form',
    'imageContainer',
    'isSharedContainer',
    'isSharedField',
    'neighborAreas',
    'nonEventContainer',
    'postLimit',
    'profileAreaFieldsController',
    'profileField',
    'reachedLimit',
    'showUserEmailContainer',
    'showUserEmailHint',
    'submitButton',
    'titleField',
    'uploadedImageContainer',
    'uploadError',
    'uppy',
  ];

  static values = {
    categories: Array,
    profiles: Object,
    hasUploadedImages: Boolean,
  };

  connect() {
    this.saveDraft = debounce(this.undebouncedSaveDraft, 10_000, {
      leading: true,
      trailing: true,
    });

    // use a timeout to wait until child controllers are connected
    setTimeout(() => {
      this.updateProfileInfo();
      this.updateAreasInfo();
      this.updateCategoryInfo();
      this.updateDestinationInfo();

      this.uppy?.on('retry-all', () => {
        if (this.hasUploadErrorTarget) {
          this.uploadErrorTarget.classList.remove('show');
        }
      });

      this.uppy?.on('upload-error', () => {
        if (this.hasUploadErrorTarget) {
          this.uploadErrorTarget.classList.add('show');
        }
      });

      this.uppy?.on('complete', () => {
        this.uppyController.resync();
        this.saveDraft();
      });

      this.uppyTarget.classList.toggle('show', !this.hasUploadedImagesValue);
      this.uploadedImageContainerTarget.classList.toggle(
        'show',
        this.hasUploadedImagesValue,
      );
    }, 0);

    this.beforeUnloadHandler = this.beforeUnload.bind(this);
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    document.addEventListener('turbo:before-visit', this.beforeUnloadHandler);
  }

  disconnect() {
    super.disconnect();

    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    document.removeEventListener(
      'turbo:before-visit',
      this.beforeUnloadHandler,
    );
  }

  beforeUnload(event) {
    this.undebouncedSaveDraft();

    const message =
      'Are you sure you want to leave this page without submitting your posting?';

    // If there are important unsubmitted values in this form, confirm that the
    // user actually wants to leave. If this is called from a turbo:before-visit
    // event (like nagivating to another member page), the above message will be
    // used in a confirmation dialog. If this is called from a beforeunload event,
    // the browser's default confirmation will be shown instead.
    //
    // eslint-disable-next-line no-alert
    if (!this.submitting && this.isDirty && !window.confirm(message)) {
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
    }
  }

  updateProfileInfo() {
    this.toggleSelectableCategories();
    this.toggleReachedLimitFields();
    this.toggleShowUserEmailContainer();
    this.toggleShowInBusinessDirectoryContainer();
  }

  updateAreasInfo() {
    const areas = this.selectedAreas;

    // Extract the distinct neighbor area names from the areas changed event,
    // and show those in the neighbor areas target.
    let names = areas.map((area) => area.neighbor_area_names).flat();
    names = Array.from(new Set(names)); // distinct
    names = names.join(', ') || 'No available areas for sharing';
    this.neighborAreasTarget.textContent = names;

    // If a custom access plan has reached its post limit and its areas
    // threshold, show the reached limit error.
    this.toggleSelectableCategories();
    this.toggleReachedLimitFields();
    this.toggleShowUserEmailContainer();
  }

  submitCalendarOnlyEvent(e) {
    this.destinationTargets.find((el) => el.value === 'calendar').click();
    this.toggleSelectableCategories();
    this.toggleReachedLimitFields();
    e.preventDefault();
  }

  // Return whether categoryId is visible to the currently-selected profile.
  categoryVisible(categoryId) {
    const category = this.categoriesData[categoryId];
    const isEvent = category?.is_event;
    const memberSelectableCategoryIds =
      this.selectedProfile?.member_selectable_category_ids || [];

    // Placeholders should always remain visible
    if (categoryId < 1) {
      return true;
    }

    // Profile type may not select category
    if (
      memberSelectableCategoryIds.length > 0 &&
      !memberSelectableCategoryIds.includes(categoryId)
    ) {
      return false;
    }

    // Profile at forum posting limit and category not Event (OCMs don't want
    // other categories that allow events to be options here)
    if (this.reachedLimit && !isEvent) {
      return false;
    }

    return true;
  }

  resetCategorySelect() {
    $(this.categoryIdFieldTarget).val(-1).trigger('change');
  }

  toggleReachedLimitFields() {
    const profile = this.selectedProfile;
    const destination = this.destinationTargets.find((el) => el.checked)?.value;
    let { reachedLimit } = this;

    // Hide the destination container if profile at posting limit -
    // Profile may only submit calendar events
    this.destinationBelowLimitContainerTarget.classList.toggle(
      'show',
      !reachedLimit,
    );

    reachedLimit = reachedLimit && destination !== 'calendar';

    // Reset category ID select to placeholder if selected profile at posting limit
    if (reachedLimit) {
      this.resetCategorySelect();
    }
    this.updateCategoryInfo();

    this.postLimitTargets.forEach((element) => {
      element.classList.remove('show');
    });

    const postLimitElements = this.postLimitTargetsForProfileId(profile?.id);

    postLimitElements.forEach((element) => {
      element.classList.add('show');
    });

    this.reachedLimitTargets.forEach((element) => {
      element.classList.toggle('show', reachedLimit);
    });

    this.belowLimitTargets.forEach((element) => {
      element.classList.toggle('show', !reachedLimit);
    });

    this.submitButtonTarget.disabled = reachedLimit;
  }

  toggleSelectableCategories() {
    const categoryOptions = [...this.categoryIdFieldTarget.options];

    // Hide non-selectable categories based on selected profile
    categoryOptions.forEach((option) => {
      const categoryId = option.value;
      option.disabled = !this.categoryVisible(Number(categoryId));

      // Reset category ID select to placeholder if currently-selected category is unavailable
      if (
        option.disabled &&
        this.categoryIdFieldTarget.value === option.value
      ) {
        this.resetCategorySelect();
      }
    });

    // Filter selectable options
    const selectableOptions = categoryOptions.filter((option) => {
      return !option.disabled && Number(option.value) > 0;
    });

    // If only one selectable category, automatically select it and hide the
    // category select field
    if (selectableOptions.length === 1) {
      this.categoryIdFieldTarget.value = selectableOptions[0].value;
      this.categoryIdFieldTarget.dispatchEvent(new Event('change'));
      this.categoryContainerTarget.classList.remove('show');
    } else {
      this.categoryContainerTarget.classList.add('show');
    }
  }

  toggleShowUserEmailContainer() {
    const profile = this.selectedProfile;
    const areas = this.selectedAreas;

    // Show the "show email author" checkbox only if the profile and all the
    // selected areas allow it and a post is being created
    const show =
      profile?.may_hide_user_email_on_post &&
      areas.length > 0 &&
      this.selectedDestination !== 'calendar';

    if (this.hasShowUserEmailContainerTarget) {
      this.showUserEmailContainerTarget.classList.toggle('show', show);
    }
  }

  toggleShowUserEmailHint(event) {
    if (this.hasShowUserEmailHintTarget) {
      this.showUserEmailHintTarget.classList.toggle(
        'show',
        !event.currentTarget.checked,
      );
    }
  }

  toggleShowInBusinessDirectoryContainer() {
    const profile = this.selectedProfile;

    this.directoryTargets.forEach((element) => {
      element.classList.toggle(
        'show',
        profile?.show_in_business_directory || false,
      );
    });
  }

  /**
   * Update fields and related info when the category is changed.
   */
  updateCategoryInfo() {
    const category = this.selectedCategory;

    // Show the category title example as a title placeholder
    this.titleFieldTarget.setAttribute(
      'placeholder',
      category.suggested_headline || '',
    );

    // Show the category FAQ as a category input hint
    this.categoryHintTarget.innerHTML = category.faq || '';

    // Hide the shared field unless the category allows sharing
    this.isSharedFieldTarget.disabled = !category.include_in_shared_postings;
    this.isSharedContainerTarget.classList.toggle(
      'show',
      category.include_in_shared_postings,
    );

    // Show the image fields if the category allows images
    this.imageContainerTarget.classList.toggle('show', this.showImagesField);

    let destination = null;
    if (!category.id) {
      // No category selected.
    } else if (
      category.is_event &&
      [null, 'forum'].includes(this.selectedDestination)
    ) {
      // Event category selected. Set default destination to calendar + forum
      // if previous destination was forum-only or null.
      destination = 'both';
    } else if (
      !(category.allow_event || category.is_event) ||
      [null].includes(this.selectedDestination)
    ) {
      // Set destination to forum if category is not event-eligable,
      // or previous destination was null.
      destination = 'forum';
    }

    // Disable forum-only Event postings
    this.destinationForumTarget.disabled = category.is_event || false;
    this.destinationForumButtonTarget.classList.toggle(
      'btn-outline-gray',
      category.is_event,
    );

    this.destinationTargets.find((el) => el.value === destination)?.click();

    // Hide the destination fields unless the category is a potential event
    const potentialEvent = category.is_event || category.allow_event || false;
    this.destinationContainerTarget.classList.toggle('show', potentialEvent);
  }

  // Show the event fields if publishing to calendar
  updateDestinationInfo() {
    const onCalendar = ['both', 'calendar'].includes(this.selectedDestination);
    this.eventContainerTargets.forEach((el) => {
      el.classList.toggle('show', onCalendar);
    });
    this.nonEventContainerTargets.forEach((el) => {
      el.classList.toggle('show', !onCalendar);
    });
    this.imageContainerTarget.classList.toggle('show', this.showImagesField);
    this.eventsMatchController.toggleEnabled(onCalendar);

    this.toggleShowUserEmailContainer();
  }

  postLimitTargetsForProfileId(profileId) {
    return this.postLimitTargets.filter((element) => {
      const id = element.getAttribute(profileIdAttr);
      return Number(id) === profileId;
    });
  }

  resetImages(event) {
    event.preventDefault();
    this.uppyTarget.classList.toggle('show', true);
    this.uploadedImageContainerTarget.innerHTML = '';
    this.uploadedImageContainerTarget.classList.toggle('show', false);
  }

  submit(event) {
    this.submitting = true;

    // If there are no images attached to the form, allow form submission via
    // the native event path
    if (!this.uppy) return;
    if (this.uppy.getFiles().length === 0) return;

    // If there are images attached to the form, then defer form submission until
    // all images have finished uploading
    event.preventDefault();
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.value = 'Submitting...';

    this.uppyController
      .waitForUpload({ timeout: 30_000 })
      .then(() => {
        this.formTarget.submit();
      })
      .catch(() => {
        this.submitting = false;
        this.submitButtonTarget.disabled = false;
        this.submitButtonTarget.value = 'Submit';
      });
  }

  undebouncedSaveDraft() {
    fetch('/compose/save_draft', {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: $(this.formTarget).serialize(),
    });
  }

  get categoriesData() {
    if (!this.categoriesData_) {
      // Transform the array of category objects into a hash from id to object
      this.categoriesData_ = Object.fromEntries(
        this.categoriesValue.map((c) => [c.id, c]),
      );
    }
    return this.categoriesData_;
  }

  get selectedCategory() {
    const categoryId = this.categoryIdFieldTarget.value;
    return (
      this.categoriesData[categoryId] || {
        include_in_shared_postings: true,
        allow_images: false,
      }
    );
  }

  get selectedProfileId() {
    return this.profileFieldTargets.find((input) => input.checked)?.value;
  }

  get selectedProfile() {
    return this.profilesData[this.selectedProfileId];
  }

  get reachedLimit() {
    let reachedLimit = this.selectedProfile?.reached_posting_limit;

    // Non-candidate custom access profiles are only limited if posting to too many areas
    if (
      reachedLimit &&
      this.selectedProfile &&
      this.selectedProfile.has_custom_access &&
      this.selectedProfile.plan_type !== 'candidate'
    ) {
      const areasThreshold =
        this.selectedProfile.custom_access_posting_counter_threshold;
      reachedLimit =
        areasThreshold && areasThreshold <= this.selectedAreas.length;
    }

    return reachedLimit;
  }

  get showImagesField() {
    return (
      !!this.selectedCategory.allow_images &&
      this.selectedDestination !== 'calendar'
    );
  }

  get profilesData() {
    return this.profilesValue;
  }

  get selectedAreas() {
    return this.profileAreaFieldsController.selectedAreas;
  }

  get selectedDestination() {
    return this.destinationTargets.find((el) => el.checked)?.value || null;
  }

  get isDirty() {
    return !!(
      this.titleFieldTarget?.value ||
      this.bodyFieldTarget?.value ||
      this.uppy?.getFiles()?.length
    );
  }

  get eventsMatchController() {
    return this.application.getControllerForElementAndIdentifier(
      this.eventsMatchControllerTarget,
      'calendar--events--match',
    );
  }

  get profileAreaFieldsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.profileAreaFieldsControllerTarget,
      'profile-area-fields',
    );
  }

  get uppyController() {
    if (!this.hasUppyTarget) return null;

    return this.application.getControllerForElementAndIdentifier(
      this.uppyTarget,
      uppyControllerName,
    );
  }

  get uppy() {
    return this.uppyController?.uppy;
  }
}
