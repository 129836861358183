import { Controller } from '@hotwired/stimulus';

import { offset, setOffset } from 'lib/positioning';

/**
 * draggable controller: Drag an element around the window.
 *
 * Applies the `mouse: move` style to the draggable element (either the handle target
 * or the primary element). Does not alter the z-index, which you may wish to set
 * high enough that the element does not end up behind other elements.
 */
export default class extends Controller {
  static targets = ['handle'];

  connect() {
    this.boundMouseDown = this.mouseDown.bind(this);
    this.boundMouseUp = this.mouseUp.bind(this);
    this.boundMouseMove = this.mouseMove.bind(this);

    this.handle.addEventListener('mousedown', this.boundMouseDown);

    this.handle.style.cursor = 'move';
  }

  disconnect() {
    this.handle.removeEventListener('mousedown', this.boundMouseDown);
    document.removeEventListener('mouseup', this.boundMouseUp);
    document.removeEventListener('mousemove', this.boundMouseMove);
  }

  mouseDown(event) {
    event.preventDefault(); // disable interaction

    // Algorithm copied from https://stackoverflow.com/a/61195046/154764

    const { height, width } = this.element.getBoundingClientRect();
    const { top, left } = offset(this.element);

    const y = top + height - event.pageY;
    const x = left + width - event.pageX;

    this.dragData = {
      height,
      width,
      y,
      x,
    };

    // mouseup will be used to end dragging, and is bound to the document in case
    // the cursor moves too fast for the element position updates and escapes the
    // element's bounds
    document.addEventListener('mouseup', this.boundMouseUp);

    // mousemove will be used to update the element's position, and is bound to
    // the document in case the cursor moves too fast for the element position
    // updates and escapes the element's bounds
    document.addEventListener('mousemove', this.boundMouseMove);
  }

  mouseUp(event) {
    event.preventDefault(); // disable interaction

    document.removeEventListener('mouseup', this.boundMouseUp);
    document.removeEventListener('mousemove', this.boundMouseMove);
  }

  mouseMove(event) {
    if (!this.dragData) {
      return;
    }

    const top = event.pageY + this.dragData.y - this.dragData.height;
    const left = event.pageX + this.dragData.x - this.dragData.width;

    setOffset(this.element, { top, left });
  }

  get handle() {
    return this.hasHandleTarget ? this.handleTarget : this.element;
  }
}
