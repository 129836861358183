import { Controller } from '@hotwired/stimulus';

/**
 * areas--issues--navigation controller: Handles the clientside aspects
 * of a turbo-stream issue render after an issue is clicked in the navigation
 */
export default class extends Controller {
  connect() {
    this.fetchRequestHandler = this.handleSlideClick.bind(this);
    document.addEventListener(
      'turbo:before-fetch-request',
      this.fetchRequestHandler,
    );

    this.updateHandler = this.updateIssue.bind(this);
    document.addEventListener('turbo:before-stream-render', this.updateHandler);
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-fetch-request',
      this.fetchRequestHandler,
    );
    document.removeEventListener(
      'turbo:before-stream-render',
      this.updateHandler,
    );
  }

  handleSlideClick(event) {
    // show spinner to indicate something is happening / loading
    const spinner = document.querySelector(
      `#${event.srcElement.dataset.turboFrame} .issue-loading`,
    );
    spinner?.classList?.remove('hide');

    // also updates styles to e.g. remove marker ahead of turbo response being available
    this.updateSlideStyles();
  }

  updateIssue(event) {
    // ignore other streams than for the issue content
    if (event.detail.newStream.target === 'issue_content')
      this.updateSlideStyles();
  }

  updateSlideStyles() {
    const displayedIssue = this.element.querySelector('.cur-issue');
    if (displayedIssue) {
      displayedIssue.classList.remove('cur-issue');
      displayedIssue.classList.add('other-issue');
      displayedIssue.querySelector('.cur-issue-marker')?.remove();
    }
  }
}
