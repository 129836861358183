import { Controller } from '@hotwired/stimulus';

/**
 * class-list controller: Add/remove/toggle CSS classes.
 *
 * Stimulus toggle target groups are identified by a group attribute:
 *
 *   stimulus('class-list',
 *     target: 'toggle',
 *     group: 'expanded')
 *
 * Stimulus actions add/remove/toggle target group CSS classes via action parameters:
 *
 *   stimulus('class-list',
 *     action: expand,
 *     action_params: {
 *       expanded: {
 *         add: 'd-block d-md-block',
 *         remove: 'd-none d-md-none',
 *       },
 *     })
 *
 * For simpler hide/show use cases, use the toggle controller.
 */
export default class extends Controller {
  static targets = ['toggle'];

  toggle(event) {
    this.toggleTargets.forEach((target) => {
      Object.entries(event.params).forEach(([group, instructions]) => {
        if (group === target.dataset.classListGroupValue) {
          Object.entries(instructions).forEach(([instruction, classNames]) => {
            classNames.split(' ').forEach((className) => {
              if (instruction === 'add') target.classList.add(className);
              if (instruction === 'remove') target.classList.remove(className);
              if (instruction === 'toggle') target.classList.toggle(className);
            });
          });
        }
      });
    });
  }
}
