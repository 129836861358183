import * as Eta from 'eta';

/**
 * Render the given Eta template with the given data and return the rendered string.
 *
 * https://eta.js.org/docs/get-started/overview
 *
 * @param {string | TemplateFunction} template The Eta template
 * @param {object} data The parameters available to the template (through the
 *  `it` object).
 * @returns {String}
 */
export function renderTemplate(template, data) {
  return Eta.render(template, data);
}

/**
 * Render the given Eta template with the given data and return an Element.
 *
 * @param {string | TemplateFunction} template The Eta template
 * @param {object} data The parameters available to the template (through the
 *  `it` object).
 * @returns {Element}
 */
export function renderElement(template, data) {
  const el = document.createElement('template');
  const html = renderTemplate(template, data).trim();
  el.innerHTML = html;
  return el.content.firstChild;
}

/**
 * Render the given Eta template with the given data and assign it to the
 * innerHTML of the given element.
 *
 * @param {Element} element The HTML element to render the template result to.
 * @param {string | TemplateFunction} template The Eta template
 * @param {object} data The parameters available to the template (through the
 *  `it` object).
 */
export function renderTo(element, template, data) {
  element.innerHTML = renderTemplate(template, data);
}
