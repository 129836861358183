import Splide from '@splidejs/splide';
import { Controller } from '@hotwired/stimulus';

/**
 * slider controller: connects the element to a Splide instance.
 *
 * https://splidejs.com/options/
 */
export default class extends Controller {
  static targets = ['sliderElement', 'endAlert'];

  connect() {
    const sliderElement = this.hasSliderElementTarget
      ? this.sliderElementTarget
      : this.element;
    sliderElement.classList.add('connected');

    // Hide arrows if it is a touch screen (https://splidejs.com/guides/arrows/)
    const arrowClasses = ['splide__arrows'];
    const touchScreen = window.matchMedia('(hover: none)').matches;
    if (touchScreen) arrowClasses.push('hide');

    this.splide = new Splide(sliderElement, {
      pagination: false,
      classes: {
        arrows: arrowClasses.join(' '),
      },
    }).mount();

    this.arrowUpdateHandler = this.renderEndAlert.bind(this);
    this.splide.on('arrows:updated', this.arrowUpdateHandler);

    this.slideClickHandler = this.handleClick.bind(this);
    this.splide.on('click', this.slideClickHandler);
  }

  // responsible for making clicks on partially visible slides
  // result in making them fully visible. The position of the clicked
  // slide depends on the focus option in the slider. By default it
  // is displayed on the left side, but if focus is set to center, then
  // it is moved to the center of the visible part of the slider.
  handleClick(slide, _event) {
    const el = slide.slide;
    if (!el.classList.contains('is-visible')) this.splide.go(slide.index);
  }

  renderEndAlert(_prev, _next, _prevIndex, nextIndex) {
    if (!this.hasEndAlertTarget) return;

    if (nextIndex <= 0) {
      this.endAlertTarget.classList.remove('hide');
    } else {
      this.endAlertTarget.classList.add('hide');
    }
  }

  disconnect() {
    if (this.splide) {
      this.splide.off('arrows:updated');
      this.splide.off('click');

      this.splide.destroy();
      delete this.splide;
    }
  }
}
