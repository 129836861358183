// Utilities for element offset

/**
 * Returns the coordinates of the element relative to the offset parent.
 *
 * Copied from https://youmightnotneedjquery.com/#position
 *
 * @param element {Element}
 * @returns {Object} with top and left keys
 */
export function position(element) {
  const { top, left } = element.getBoundingClientRect();
  const { marginTop, marginLeft } = getComputedStyle(element);

  return {
    top: top - parseInt(marginTop, 10),
    left: left - parseInt(marginLeft, 10),
  };
}

/**
 * Returns the coordinates of the element relative to the document.
 *
 * Copied from https://youmightnotneedjquery.com/#offset
 *
 * @param element {Element}
 * @returns {Object} with top and left keys
 */
export function offset(element) {
  const bounds = element.getBoundingClientRect();
  const docElem = document.documentElement;

  const top = bounds.top + window.pageYOffset - docElem.clientTop;
  const left = bounds.left + window.pageXOffset - docElem.clientLeft;

  return { top, left };
}

/**
 * Sets the coordinates of the element relative to the document.
 *
 * Copied from https://github.com/jquery/jquery/blob/3.7.1/src/offset.js#L20
 *
 * @param element {Element}
 * @param top {Number}
 * @param left {Number}
 */
export function setOffset(element, { top = null, left = null } = {}) {
  const pos = element.style.position;
  const props = {};

  // Set position first, in-case top/left are set even on static elem
  if (pos === 'static') {
    element.style.position = 'relative';
  }

  const curOffset = offset(element);
  const curCSSTop = element.style.top;
  const curCSSLeft = element.style.left;
  const calculatePosition =
    (pos === 'absolute' || pos === 'fixed') &&
    (curCSSTop + curCSSLeft).indexOf('auto') > -1;

  // Need to be able to calculate position if either top or left is auto and
  // position is either absolute or fixed
  let curPosition;
  let curTop;
  let curLeft;

  if (calculatePosition) {
    curPosition = position(element);
    curTop = curPosition.top;
    curLeft = curPosition.left;
  } else {
    curTop = parseFloat(curCSSTop) || 0;
    curLeft = parseFloat(curCSSLeft) || 0;
  }

  if (top != null) {
    props.top = `${top - curOffset.top + curTop}px`;
  }
  if (left != null) {
    props.left = `${left - curOffset.left + curLeft}px`;
  }

  Object.assign(element.style, props);
}
