import { Controller } from '@hotwired/stimulus';
import { Uppy, debugLogger } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import ActiveStorageUpload from 'lib/uppy_activestorage_upload';
import Deferrer from 'lib/deferrer';

// Specify the image types that we accept for uploaded images.
//
// NOTE: these are also defined in config/initializers/libvips.rb and used in
// various parts of the Rails app (e.g. active storage validators).
const ACCEPTED_IMAGE_EXTENSIONS = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'heif',
  'heic',
  'webp',
];
const ACCEPTED_IMAGE_TYPES_ARRAY = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/heif',
  'image/heic',
];
const ACCEPTED_IMAGE_PHRASE = 'gif, jpg, png, webp, heif or heic';

/**
 * Uppy-backed image upload UI.
 *
 * When a file is added to Uppy's upload widget, the ActiveStorageUpload Uppy
 * plugin immediately uploads the file to Active Storage and returns a signed
 * ID. We then take the signed ID and use it to create a hidden input, allowing
 * the Active Storage blob to be associated with the parent record on form
 * submission.
 *
 * Note: As of 2021, all modern browsers allow file input values to be directly
 * set via JS, allowing Uppy to be used as a entirely front-end tool without any
 * Rails/Active Storage integration
 * (see https://pqina.nl/blog/set-value-to-file-input). However, supporting
 * older browsers (e.g. Safari < 14.1) requires the legacy ActiveStorageUpload
 * plugin.
 */
export default class extends Controller {
  static values = {
    inputName: String,
    minFileSize: Number, // min file size limit, in bytes
    maxFileSize: Number, // max file size limit, in bytes
    maxNumberOfFiles: Number,
    note: String,
    uploadUrl: String,
  };

  connect() {
    // Manually validate against dng and jfif file uploads
    // as the built-in allowedFileTypes option fails to prevent them.
    const fileTypeMessage = `Must be an image file (${ACCEPTED_IMAGE_PHRASE}).`;
    const onBeforeFileAdded = (file) => {
      const extension = file.name.toLowerCase().split('.').pop();

      if (!ACCEPTED_IMAGE_EXTENSIONS.includes(extension)) {
        this.uppy.info(fileTypeMessage, 'error', 5000);
        return false;
      }

      return true;
    };

    this.uppy = new Uppy({
      autoProceed: true,
      locale: {
        strings: {
          youCanOnlyUploadFileTypes: fileTypeMessage,
        },
      },
      logger: debugLogger,
      restrictions: {
        allowedFileTypes: ACCEPTED_IMAGE_TYPES_ARRAY,
        minFileSize: this.minFileSizeValue || null,
        maxFileSize: this.maxFileSizeValue || null,
        maxNumberOfFiles: this.maxNumberOfFilesValue || null,
      },
      onBeforeFileAdded,
    });

    this.uppy.use(ActiveStorageUpload, {
      directUploadUrl: this.uploadUrlValue,
    });

    this.uppy
      .use(Dashboard, {
        height: '120px',
        hideCancelButton: true,
        hideUploadButton: true,
        inline: true,
        locale: {
          strings: {
            dropPasteFiles: 'Drop images here or %{browseFiles}',
          },
        },
        note: this.noteValue,
        proudlyDisplayPoweredByUppy: false,
        showRemoveButtonAfterComplete: true,
        target: this.element,
        width: '100%',
      })
      .use(ImageEditor, { target: Dashboard });

    // Initialize Uppy with a height of 120px, but increase to 472px upon
    // attachment of a file. Ideally, we'd just configure Uppy with the height
    // set to auto, but that throws a "ResizeObserver loop completed with
    // undelivered notifications" error.
    this.uppy.once('file-added', () => {
      const dashboard = this.element.querySelector('.uppy-Dashboard-inner');
      $(dashboard).animate({ height: '472px' }, 400);
    });

    // Attach a deferrer to every file to monitor upload progress.
    // The deferrer contains a promise that will resolve upon upload completion
    // (or reject upon an error).
    this.uppy.on('file-added', (file) => {
      file.deferrer = new Deferrer();
    });

    // Reset deferrers before re-attempting failed uploads.
    this.uppy.on('retry-all', (fileIds) => {
      fileIds.forEach((fileId) => {
        const file = this.uppy.getFile(fileId);
        file.deferrer = new Deferrer();
      });
    });

    // Resolve deferrers upon success or reject upon failure.
    this.uppy.on('upload-success', (file) => {
      file.deferrer.resolve();
    });

    this.uppy.on('upload-error', (file, error) => {
      file.deferrer.reject(error);
    });
  }

  /**
   * Wait until all files have finished uploading to ActiveRecord/S3 before
   * proceeding. Returns a promise, which resolves once all files upload
   * successfully and otherwise rejects.
   *
   * @param timeout {Integer} a timeout in milliseconds, after which the
   *   returned promise rejects.
   */
  waitForUpload({ timeout = null } = {}) {
    // Automatically re-attempt any failed uploads.
    this.uppy.retryAll();

    return new Promise((resolve, reject) => {
      const deferrers = this.uppy.getFiles().map((f) => f.deferrer);
      if (timeout) {
        deferrers.forEach((deferrer) => deferrer.setTimeout(timeout));
      }

      // Wait for all upload deferrer promises to settle.
      const promises = deferrers.map((deferrer) => deferrer.promise);
      Promise.allSettled(promises).then((results) => {
        const failures = results.filter(
          (promise) => promise.status === 'rejected',
        );

        if (failures.length === 0) {
          this.resync();
          return resolve();
        }

        const error = new Error('[Uppy] Some files failed to upload');
        return reject(error);
      });
    });
  }

  // Before form submission, append a hidden input with a value of a signed
  // ActiveStorage blob ID for each file attachment.
  resync() {
    // Remove existing inputs to avoid creating duplicates.
    const selector = `input[name='${this.inputNameValue}']`;
    const oldInputs = this.element.querySelectorAll(selector);
    oldInputs.forEach((input) => {
      input.remove();
    });

    // Create a new hidden input for each uploaded file.
    this.uppy.getFiles().forEach((file) => {
      if (!file.response) return;

      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', this.inputNameValue);
      hiddenField.setAttribute('value', file.response.signed_id);
      this.element.append(hiddenField);
    });
  }
}
