import { Controller } from '@hotwired/stimulus';
import * as dayjs from 'dayjs';

import FieldValidation from 'components/validate/field_validation';

/**
 * calendar--event--date-fields controller: date fields logic.
 *
 * Update method keeps hidden fields in sync with visible fields.
 * Validate method validates some of the fields.
 *
 * Values:
 * - altInput {boolean}: if true, validation will be applied to an alt input
 *    field, which must be the next sibling of the original input field. This
 *    supports the flat-pickr altInput option.
 */
export default class extends Controller {
  static targets = [
    'endDate',
    'endDateTime',
    'endDateContainer',
    'endTime',
    'recurringCheckbox',
    'showEndTime',
    'showStartTime',
    'startDate',
    'startDateTime',
    'startTime',
  ];

  static values = { altInput: Boolean };

  connect() {
    this.update({ validate: false });
  }

  /**
   * Keep the hidden fields in sync when the visible inputs change.
   */
  update({ validate = true } = {}) {
    // Update the hidden "show time" fields based on whether there's a value
    // in the related visible time fields
    this.showStartTimeTarget.value = this.startTimeTarget.value.trim()
      ? '1'
      : '0';
    this.showEndTimeTarget.value = this.endTimeTarget.value.trim() ? '1' : '0';

    // Copy the start date to the end date if end date is blank but end time
    // is not blank
    if (!this.endDateTarget.value.trim() && this.endTimeTarget.value.trim()) {
      this.endDateTarget.value = this.startDateTarget.value;
    }

    // Copy visible date and times to hidden date-time fields
    this.startDateTimeTarget.value = `${this.startDateTarget.value} ${this.startTimeTarget.value}`;
    this.endDateTimeTarget.value = `${this.endDateTarget.value} ${this.endTimeTarget.value}`;

    // Hide the end date field for recurring events, because a multi-day
    // recurring event is confusing
    if (this.hasRecurringCheckboxTarget && this.hasEndDateContainerTarget) {
      const hideEndDate = !!this.recurringCheckboxTarget.checked;
      this.endDateContainerTarget.classList.toggle('d-none', hideEndDate);

      // Set the hidden end date-time field value to the *start* date and *end* time
      if (hideEndDate) {
        this.endDateTimeTarget.value = `${this.startDateTarget.value} ${this.endTimeTarget.value || this.startTimeTarget.value}`;
      }
    }

    if (validate) {
      this.validate();
    }
  }

  /**
   * Validate some of the visible inputs.
   */
  validate() {
    this.validateStartDate();
  }

  validateStartDate() {
    let input = this.startDateTarget;
    if (this.altInputValue && input.nextElementSibling?.tagName === 'INPUT') {
      input = input.nextElementSibling;
    }

    const validation = new FieldValidation(input);

    const startDateTime = dayjs(this.startDateTimeTarget.value);

    if (!this.startDateTarget.value) {
      validation.show('Start date is required');
    } else if (!startDateTime.isValid) {
      validation.show('Start date is not valid');
    } else if (startDateTime.isBefore(dayjs().startOf('day'))) {
      validation.show('Start date has already passed');
    } else if (
      startDateTime.isBefore(Date.now()) &&
      this.startTimeTarget.value.trim().length > 0
    ) {
      validation.show('Start time has already passed');
    } else {
      validation.clear();
    }
  }
}
